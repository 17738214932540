<template>
  <div class="share">
    <div class="title">
      <div class="title-line"><span>Give the Gift</span></div>
      <div class="title-line"><span>of&nbsp;</span><span class="blue-text">&nbsp;Pure Air</span></div>
    </div>
    <p class="subtitle">To your employees and your clients</p>
    <div class="body-text">
      <p>If we want to achieve an inclusive and sustainable future, we cannot solve this alone: our vision is to inspire 1 billion people to act now. Support us by spreading the word through your social channels.</p>
    </div>
    <div class="share-icons">
      <div>
        <div class="share-button">
          <a href="https://api.whatsapp.com/send?text=Climeworks%20offers%20a%20technology%20to%20reverse%20climate%20change.%0D%0Ahttps%3A%2F%2Fthegiftofpureair.com%2F%0D%0AClimeworks%20is%20the%20world%27s%20leading%20direct%20air%20capture%20company%20and%20provides%20a%20solution%20that%20empowers%20everyone%20to%20be%20climate%20positive%20by%20removing%20carbon%20dioxide%20from%20air." target="_blank" rel="noreferrer"></a>
        </div>
        <div class="share-button">
          <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthegiftofpureair.com%2F&title=Climeworks%20offers%20a%20technology%20to%20reverse%20climate%20change.&description=Climeworks%20is%20the%20world%27s%20leading%20direct%20air%20capture%20company%20and%20provides%20a%20solution%20that%20empowers%20everyone%20to%20be%20climate%20positive%20by%20removing%20carbon%20dioxide%20from%20air.&quote=&hashtag=" target="_blank" rel="noreferrer"></a>
        </div>
        <div class="share-button">
          <a href="https://twitter.com/intent/tweet?text=Climeworks%20offers%20a%20technology%20to%20reverse%20climate%20change.&url=https%3A%2F%2Fthegiftofpureair.com%2F" target="_blank" rel="noreferrer"></a>
        </div>
        <div class="share-button">
          <a href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fthegiftofpureair.com%2F" target="_blank" rel="noreferrer"></a>
        </div>
        <div class="share-button">
          <a href="mailto:?subject=Climeworks offers a technology to reverse climate change.&body=https://thegiftofpureair.com/
%0D%0AClimeworks is the world's leading direct air capture company and provides a solution that empowers everyone to be climate positive by removing carbon dioxide from air." target="_blank" rel="noreferrer"></a>
        </div>
      </div>
    </div>
    <div @click="hide" class="cta-button share-exit">Go back</div>
  </div>
</template>

<script>
export default {
  name: 'Share',
  methods: {
    hide() {
      document.querySelector('.share').classList.remove('visible');
    }
  }
}
</script>

<style scoped lang="scss">
.share {
  background: #ffffff;
  opacity: 0;
  padding: 66px 63px 80px;
  pointer-events: none;
  text-align: center;
  transition: all .3s ease-in-out;
  z-index: 999;
  transform: translateY(30px);

  @media screen and (max-width: 769px) {
    padding: 30px 30px 30px;
    margin-top: 20px;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  &-exit {
    margin-top: 30px;
  }
}

.title {
  font-family: Albra;
  font-size: 75px;
  margin: 22px 0 44px;
  line-height: 70px;

  &.appear .title-line span {
    transform: translateY(0);
    opacity: 1;
  }

  @media screen and (max-width: 769px) {
    font-size: 44px;
    line-height: 41px;
    margin: 0 0 25px;
  }
}

.title-line {
  display: block;

  &:nth-child(2) span:first-child {
    transition: all .4s ease-in-out .1s;
  }

  span {
    display: inline-block;
    transition: all .4s ease-in-out;
  }
}

span.blue-text {
  color: #5DBAD8;
  transition: all .4s ease-in-out .2s;
  margin-left: 8px;

  @media screen and (min-width: 769px) {
    margin-left: 13px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  @media screen and (max-width: 769px) {
    font-size: 14px;
  }
}

.body-text {
  font-size: 16px;
  line-height: 20.8px;
  margin: 25px auto 60px;

  @media screen and (max-width: 769px) {
    margin: 25px auto;
  }

  p {
    margin: 0 0 30px;
  }
}

.share-icons {
  display: flex;
  margin-top: 32px;
  justify-content: center;

  > div {
    display: flex;
  }
}

.share-button {
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media screen and (max-width: 769px) {
    width: 30px;
    height: 30px;
  }

  &:hover a {
    transform: translateY(-10px);
  }

  &:not(:last-child) {
    margin-right: 24px;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    transition: transform .4s cubic-bezier(.32,.94,.6,1);
  }

  &:first-child a {
    background-image: url('/icons/whatsapp-black.svg');
  }
  &:nth-child(2) a {
    background-image: url('/icons/facebook-black.svg');
  }
  &:nth-child(3) a {
    background-image: url('/icons/twitter-black.svg');
  }
  &:nth-child(4) a {
    background-image: url('/icons/linkedin-black.svg');
  }
  &:nth-child(5) a {
    background-image: url('/icons/email-black.svg');
  }
}
</style>
