<template>
  <div class="card-container">
    <div data-card class="card">
      <Intro />
      <Calculator />
      <Share />
    </div>
    <div class="ctas">
      <a href="mailto:lia.Flury@climeworks.com" target="_blank" rel="noreferrer">
        Contact us
      </a>
      <p @click="showSharing">Share</p>
    </div>
  </div>
</template>

<script>
import Intro from './Intro.vue';
import Calculator from './Calculator.vue';
import Share from './Share.vue';

export default {
  name: 'Card',
  components: {
    Calculator,
    Intro,
    Share,
  },
  methods: {
    showSharing() {
      document.querySelector('.share').classList.toggle('visible');
    }
  }
};
</script>

<style scoped lang="scss">

.card-container {
  margin: 5vh auto 0;
  width: 713px;

  @media screen and (max-width: 769px) {
    width: 100%;
    margin: 30px auto 0;
  }

  @media screen and (min-width: 769px) and (max-height: 850px) {
    margin: 25px auto 0;
    transform-origin: top;
    // transform: scale(0.9);
  }

  @media screen and (min-width: 769px) and (max-height: 700px) {
    margin: 25px auto 0;
    transform-origin: top;
    // transform: scale(0.8);
  }
}

.card {
  height: 600px;
  position: relative;
  width: 713px;
  margin-bottom: 32px;

  @media screen and (max-width: 769px) {
    width: 100%;
    height: auto;
  }
}

.ctas {
  @media screen and (min-width: 769px) {
    margin-bottom: 20px;
  }

  a, p {
    color: #000000;
    padding: 14px 27px;
    display: inline-block;
    border: 1px solid #000000;
    border-radius: 40px;
    margin-right: 12px;
    cursor: pointer;
    transition: border-radius .3s ease-in-out;

    &:hover {
      border-radius: 0;
    }
  }
}

</style>

<style lang="scss">
.card,
.calculator,
.share,
.intro {
  border-radius: 40px;
  box-sizing: border-box;
  height: 600px;
  position: relative;
  width: 713px;

  @media screen and (max-width: 769px) {
    height: auto;
    width: 100%;
  }
}

.calculator,
.intro {
  backface-visibility: hidden;
  background: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
  transition: transform 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  @media screen and (max-width: 769px) {
    position: static;
    transform: rotateY(0);
  }
}

.card--flipped {
  .intro {
    transform: rotateY(180deg);
    pointer-events: none;

    @media screen and (max-width: 769px) {
      transform: translateY(20px);
      opacity: 0;
      transition: all .5s ease-in-out;
    }
  }
  .calculator {
    transform: rotateY(360deg);
    pointer-events: all;

    @media screen and (max-width: 769px) {
      transform: translateY(0);
      opacity: 1;
      transition: all .5s ease-in-out;
    }
  }
}

@media screen and (min-width: 769px) and (max-height: 850px) {
  // .input-text,
  // .second-row__description
  // {
  //   font-size: 16px!important;
  // }

  // .second-row__description {
  //   max-width: 280px;
  // }

  // .body-text {
  //   font-size: 18px;
  // }
}
</style>
