<template>
  <div class="container">
    <a href="https://climeworks.com/" target="_blank" rel="noreferrer" class="logo">
      <img src="../assets/img/logo.svg" alt="Logo Climeworks">
    </a>
    <Card />
    <p class="copywright">© 2020 Climeworks</p>
    <div class="bottom-bar">
      <a href="https://www.climeworks.com/contact" target="_blank" rel="noreferrer">Contacts</a>
      <a href="https://www.climeworks.com/website--terms--use" target="_blank" rel="noreferrer">Terms of Use</a>
      <a href="https://www.climeworks.com/website-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue'

export default {
  name: 'Container',
  props: {
  },
  components: {
    Card
  }
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  padding: 36px 45px;
  position: relative;

  @media screen and (max-width: 769px) {
    padding: 26px 18px 0;
  }

  @media screen and (min-width: 769px) and (max-height: 850px) {
    padding: 26px 24px;
  }
}

.logo {
  img {
    max-width: 300px;

    @media screen and (max-width: 769px) {
      max-width: 164px;
    }

    @media screen and (min-width: 769px) and (max-height: 850px) {
      max-width: 190px;
    }
  }
}

.copywright {
  position: fixed;
  bottom: 10px;
  left: 32px;

  @media screen and (max-width: 769px) {
    position: static;
    margin: 30px auto 10px;
    text-align: center;
  }
}

.bottom-bar {
  background: #ffffff;
  border-radius: 40px 40px 0px 0px;
  bottom: -1px;
  box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
  display: inline-block;
  padding: 15px 37px 11px;
  position: fixed;
  right: 71px;
  z-index: 20;

  @media screen and (max-width: 769px) {
    padding: 12px 30px 8px;
    position: static;
    margin: auto;
    display: block;
    max-width: 300px;
  }

  a {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 500;

    @media screen and (max-width: 769px) {
      font-size: 12px;
    }
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
}
</style>
