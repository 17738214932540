<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Container />
  </div>
</template>

<script>
import Container from './components/Container.vue';

export default {
  name: 'App',
  components: {
    Container,
  }
};
</script>

<style lang="scss">
@font-face {
  font-family: Centra;
  font-weight: 400;
  src: local("Centra"),
   url('./assets/fonts/CentraNo1-Book.otf') format("opentype");
}

@font-face {
  font-family: Centra;
  font-weight: 500;
  src: local("Centra"),
   url('./assets/fonts/CentraNo1-Medium.otf') format("opentype");
}

@font-face {
  font-family: Albra;
  font-weight: 700;
  src: local("Albra"),
   url('./assets/fonts/Albra-Bold.otf') format("opentype");
}

#app {
  font-family: Centra, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
p {
  margin: 0;
}

body {
  background-attachment: fixed;
  background-image: url('./assets/img/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html,
body,
#app {
  overflow-x: hidden;

  @media screen and (min-width: 769px) {
    height: 100%;
    // overflow-y: hidden;
  }
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.hidden-desktop {
  display: none;

  @media screen and (max-width: 769px) {
    display: block;
  }
}

input:focus,
select:focus,
button:focus {
  outline: none;
}

input:focus::placeholder {
  color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.cta-button {
  background-color: #fdeb8c;
  border-radius: 32px;
  border: 2px solid #fdeb8c;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  outline: none;
  padding: 9px 24px;
  transition: all .2s ease-in-out;

  &:hover {
    border-radius: 0;
  }
}

.hidden-mobile {
  @media screen and (max-width: 769px) {
    display: none;
  }
}
</style>
