<template>
  <div class="success">
    <p class="greetings">
      Thank you!
    </p>
    <p class="text">
      <span>
        Thanks for joining the journey towards a climate-positive world.<br />
        We have received your request.<br />
        You will receive your order confirmation and invoice via email shortly.
      </span>
      <span>
        To learn more about us, visit our website and connect with us on LinkedIn.
      </span>
      <span>
        Your Climeworks Team
      </span>
    </p>
    <div class="trees">
      <img class="tree" src="../assets/img/tree-light.svg" alt="" />
      <img class="tree" src="../assets/img/tree-dark.svg" alt="" />
      <img class="tree" src="../assets/img/tree-white.svg" alt="" />
      <img class="tree" src="../assets/img/tree-light.svg" alt="" />
      <img class="tree" src="../assets/img/tree-white.svg" alt="" />
      <img class="tree" src="../assets/img/tree-dark.svg" alt="" />
      <img class="tree" src="../assets/img/tree-white.svg" alt="" />
    </div>
    <a
      href="https://climeworks.com/"
      target="_blank"
      rel="noreferrer"
      class="success-button cta-button"
    >
      Climeworks.com
    </a>
    <a
      href="https://www.linkedin.com/company/climeworks/"
      target="_blank"
      rel="noreferrer"
      class="success-button cta-button"
    >
      LinkedIn
    </a>
  </div>
</template>

<script>
export default {
  name: 'Success',
};
</script>

<style scoped lang="scss">
.success {
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  margin: 0 auto;
  max-width: 90%;
  padding-top: 30px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  opacity: 0;
  transition: opacity .4s ease-in-out .8s;
  pointer-events: none;
}

.greetings {
  font-family: Albra;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
}

.text {
  color: #1A1A1A;
  font-size: 16px;
  line-height: 21px;

  a {
    color: #1A1A1A;
    text-decoration: underline;
    text-underline-position: under;
  }

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.success-button {
  position: absolute;
  bottom: -84px;
  max-width: 188px;
  box-sizing: border-box;

  @media screen and (max-width: 769px) {
    position: static;
    display: block;
    margin: 0 auto 10px;
  }

  &:nth-of-type(1) {
    left: 96px;
  }
  &:nth-of-type(2) {
    right: 120px;
  }
}

.trees {
  display: block;
  height: 91px;
  margin: 0 auto;
  position: relative;
  transform: scale(0.51);
  width: 246px;

  .tree {
    position: absolute;
    bottom: 0;
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity .8s ease-in-out, transform .7s ease-in-out;

    &:first-child {
      left: 0;
      width: 52px;
      z-index: 10;
    }
    &:nth-child(2) {
      left: 35px;
      width: 74px;
      z-index: 9;
    }
    &:nth-child(3) {
      left: 92px;
      width: 52px;
      z-index: 8;
    }
    &:nth-child(4) {
      left: 130px;
      width: 34px;
      z-index: 9;
    }
    &:nth-child(5) {
      left: 141px;
      width: 74px;
    }
    &:nth-child(6) {
      left: 165px;
      width: 57px;
      z-index: 7;
    }
    &:nth-child(7) {
      left: 208px;
      width: 35px;
      z-index: 6;
    }
  }
}
</style>
<style lang="scss">
.formSent .success {
  opacity: 1;
  pointer-events: all;
}
</style>
