<template>
  <div class="intro">
    <div class="title">
      <div class="title-line"><span>Give the Gift</span></div>
      <div class="title-line"><span>of&nbsp;</span><span class="blue-text">&nbsp;Pure Air</span></div>
    </div>
    <p class="subtitle">To your employees and your clients</p>
    <div class="body-text">
      <p>Empower your employees, partners or clients to help reverse climate change.</p>
      <p>Remove <span>20kg of CO<sub>₂</sub> per person</span> from the air permanently.<br class="hidden-mobile" />
      It takes 10-15 years for a five-meter tall pine tree to store this much carbon dioxide. Our new generation of air capture technology can remove the same amount from the air in less than three minutes and provide it to permanent storage.</p>
      <p>Choose a gift, and we’ll do the rest.</p>
    </div>
    <div class="button-container">
      <button v-on:click="greet" class="cta-button">Get started</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  mounted: function() {
    setTimeout(() => {
    document.querySelector('.title').classList.add('appear');
    }, 500);
  },
  methods: {
    greet: e => {
      e.preventDefault();
      document.querySelector('[data-card]').classList.add('card--flipped');
    },
  }
}
</script>

<style scoped lang="scss">
.intro {
  padding: 36px 76px;
  text-align: center;
  z-index: 10;

  @media screen and (max-width: 769px) {
    padding: 22px 19px 55px;
    position: absolute;
    transform: translateY(0);
  }
}

.eyebrow {
  font-size: 16px;
  line-height: 19px
}

.title {
  font-family: Albra;
  font-size: 75px;
  margin: 22px 0 14px;
  line-height: 70px;

  &.appear .title-line span {
    transform: translateY(0);
    opacity: 1;
  }

  @media screen and (max-width: 769px) {
    font-size: 44px;
    line-height: 41px;
  }
}

.title-line {
  display: block;

  &:nth-child(2) span:first-child {
    transition: all .4s ease-in-out .1s;
  }

  @media screen and (min-width: 769px) {
    position: relative;
    height: 70px;
    overflow: hidden;
  }

  span {
    left: 0;
    display: inline-block;
    transition: all .4s ease-in-out;

    @media screen and (min-width: 769px) {
      transform: translateY(80px);
      opacity: 0;
    }
  }
}

span.blue-text {
  color: #5DBAD8;
  transition: all .4s ease-in-out .2s;
  margin-left: 8px;

  @media screen and (min-width: 769px) {
    margin-left: 13px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 500;

  @media screen and (max-width: 769px) {
    font-size: 14px;
  }
}

.body-text {
  font-size: 16px;
  line-height: 20.8px;
  margin: 25px auto 60px;

  @media screen and (max-width: 769px) {
    margin: 25px auto;
  }

  p {
    margin: 0 0 30px;
  }

  span {
    font-weight: 700;

    @media screen and (max-width: 769px) {
      display: block;
    }
  }
}

.cta-button {
  background-color: #fdeb8c;
  border-radius: 32px;
  border: 2px solid #fdeb8c;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  outline: none;
  padding: 9px 24px;
  transition: all .2s ease-in-out;

  &:hover {
    border-radius: 0;
  }
}
</style>
