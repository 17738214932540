<template>
  <div class="form">
    <form v-on:submit.prevent="handleForm">
      <p class="form-description">
        Provide your contact details below. We will be in touch shortly to process your order and organize your gift.
      </p>
      <div class="form-container">
        <div class="input-group">
          <input @input="checkForm" class="input" type="text" placeholder="name" v-model="form.first_name">
        </div>
        <div class="input-group">
          <input @input="checkForm" class="input" type="text" placeholder="surname" v-model="form.last_name">
        </div>
        <div class="input-group">
          <input @input="checkForm" class="input" type="text" placeholder="company name" v-model="form.company">
        </div>
        <div class="input-group">
          <input @input="checkForm" class="input" type="text" placeholder="email address" v-model="form.email">
        </div>
        <div class="consent-container">
          <div class="consent">
            <div class="checkbox-style" tabIndex={0}>
              <input @input="checkForm" class="checkbox" tabIndex={0} type="checkbox" id="consent"/>
              <div class="fake-checkbox"></div>
            </div>
            <label for="consent" class="checkbox-label">
              I agree to the <a href="https://climeworkscom.cdn.prismic.io/climeworkscom/7b916b62-26ae-400d-8976-213c9b91e21d_Terms+and+Conditions+for+Climeworks+CDR+services+-+Gift+of+pure+air+-+November+2020.pdf" download target="_blank" rel="noreferrer">terms and conditions</a> for Climeworks CDR services
            </label>
          </div>
          <button :class="{'disabled': !allowSubmit}" @click="handleForm" class="submit-button cta-button">
            Submit your order
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Form',
  props: {
    currency: String,
    package: String,
    employees: String,
    total: Number,
    impact: Number,
  },
  data() {
    return {
      allowSubmit: false,
      form: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        total: this.total,
        employees: this.employees,
        currency: this.currency,
        package: this.package,
      }
    }
  },
  watch: {
    package: function(newVal) {
      this.form.package = newVal;
    },
    currency: function(newVal) {
      this.form.currency = newVal;
    },
    employees: function(newVal) {
      this.form.employees = newVal;
    },
    total: function(newVal) {
      this.form.total = newVal;
    },
  },
  methods: {
    checkForm() {
      const checkbox = document.querySelector('#consent');
      const isValid = this.form.first_name.length > 0 &&
      this.form.last_name.length > 0 &&
      this.form.company.length > 0 &&
      this.form.email.length > 0 &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.form.email) &&
      checkbox.checked;
      this.allowSubmit = isValid;
    },
    showConfirmation() {
      window.scrollTo(0, 0);
      document.querySelector('.calculator').classList.add('formSent');
      document.querySelector('.label-summary').classList.add('hidden');
      document.querySelector('.label-confirmation').classList.add('visible');
    },
    handleForm(e) {
      e.preventDefault();
      if (this.allowSubmit) {
        axios.post('/.netlify/functions/order', {...this.form, impact: this.impact})
          .then((res) => {
            console.log(res);
            this.showConfirmation();
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
          });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form {
  box-sizing: border-box;
  left: 0;
  opacity: 0;
  padding: 0 30px;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 132px;
  width: 100%;
  transition: opacity .6s ease-in-out;
}

.form-description {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  margin: 0 auto;
  max-width: 380px;
  text-align: center;
  margin-bottom: 23px;

  @media screen and (max-width: 769px) {
    margin-top: 110px;
  }
}

.input-group {
  width: calc(50% - 8px);
  position: relative;
  display: inline-block;

  @media screen and (max-width: 769px) {
    display: block;
    width: 100%;
  }

    &:nth-child(odd) {
      @media screen and (min-width: 769px) {
        margin-right: 14px;
      }
  }

  &::after {
    background: #ffffff;
    bottom: 25px;
    content: '';
    height: 1px;
    position: absolute;
    width: calc(100% - 107px);
    left: 54px;

    @media screen and (max-width: 769px) {
      bottom: 13px;
    }
  }
}

.input {
  background: #5DBAD8;
  border-radius: 40px;
  border: none;
  box-sizing: border-box;
  color: #ffffff;
  font-family: Centra;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 11px;
  padding: 11px 53px 17px;
  position: relative;
  text-align: center;
  width: 100%;

  &:focus::placeholder {
    color: transparent;
  }

  @media screen and (max-width: 769px) {
    display: block;
    width: 100%;
    padding: 11px 23px 17px;
    font-size: 16px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #ffffff;
  }

  &::placeholder {
    color: #ffffff;
    font-family: Centra;
    font-weight: 700;
    text-align: center;
    font-size: 18px;

    @media screen and (max-width: 769px) {
      font-size: 16px;
    }
  }
}

.cta-button {
  position: absolute;
  right: 68px;
  bottom: -102px;

  @media screen and (max-width: 769px) {
    bottom: -200px;
    left: 0;
    margin: auto;
    right: 0;
  }
}

.consent {
  bottom: -48px;
  display: flex;
  position: absolute;
  right: 68px;

  @media screen and (max-width: 769px) {
    left: 0;
    right: 0;
    bottom: -140px;
    margin: auto;
    max-width: 210px;
  }
}

input[type="checkbox"].checkbox {
  width: 16px;
  height: 16px;
  opacity: 0;
  cursor: pointer;

  &:checked + .fake-checkbox:after {
    // background: url(checked.svg) no-repeat 50%;
    background: #5DBAD8;
  }
}

.fake-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  flex-shrink: 0;
  pointer-events: none;
  flex-grow: 0;
  border: 1px solid #BFBFBF;
  outline: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 12px;
    width: 12px;
    left: 0;
    margin: auto;
    border-radius: 3px;
    background: transparent;
    transition: background .3s ease-in-out;
  }
}

.checkbox-label {
  cursor: pointer;
  color: #888888;
  font-size: 11px;
  text-align: center;
  line-height: 15px;
  max-width: 176px;

  a {
    text-decoration: underline;
    cursor: pointer;
    color: #888888;
  }
}

.checkbox-style {
  position: relative;
  margin-right: 8px;
  cursor: pointer;
  height: 1.6em;
  width: 1.6em;
  flex-shrink: 0;
  flex-grow: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 100ms;
}

.submit-button {
  &.disabled {
    background: #F1F1F1;
    cursor: not-allowed;
    border: 2px solid #F1F1F1;

    &:hover {
      border-radius: 32px;
    }
  }
}

</style>

<style lang="scss">
.summary {
  .form {
    opacity: 1;
    pointer-events: all;
    transition: opacity .6s ease-in-out;
  }

  .second-row {
    padding: 22px 35px 0;
    cursor: inherit;
    opacity: 1;
  }

  .option--active {
    background: #ffffff!important;
  }
}

.label-summary.hidden{
  display: none;
}

.label-confirmation {
  display: none;

  &.visible {
    display: block;
  }
}

.formSent {
  .input-wrapper,
  .total,
  .submit-button,
  .validate-button {
    opacity: 0;
    pointer-events: none;
  }

  .input-wrapper--summary,
  .input-wrapper.input-wrapper--summary,
  .last-row {
    @media screen and (max-width: 769px) {
      display: none;
    }
  }

  .form {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
