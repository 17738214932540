<template>
  <div :class="{'summary': summary}" class="calculator">
    <div class="first-row">
      <p class="first-row__text">
        Give the Gift
        of<br class="hidden-desktop"/> <span class="blue-text">Pure Air</span><br />
        <span class="label-summary" v-if="summary">Summary</span>
        <span class="label-calculator" v-else>Calculator</span>
        <span class="label-confirmation">Confirmation</span>
      </p>
      <div :class="{'input-wrapper--summary': summary}" class="input-wrapper">
        <p v-if="summary" class="input-text input-text--summary">
          Total number <br class="hidden-mobile"/>of employees
          <span @click="backToCalculator">(Go back and change number)</span>
        </p>
        <p v-else class="input-text">
          Enter the number<br/> of employees
        </p>
        <div class="input">
          <input
            v-model="employees"
            @input="handleSum"
            name="employees"
            type="text"
            min="1"
            maxlength="4"
            max="9999"
            pattern="[0-9]{10}"
            placeholder="input"
          >
        </div>
      </div>
    </div>
    <div :class="{'summary': summary, 'hidden': !employees}" class="second-row">
      <p class="second-row__description">
        Select an option below and discover the total impact your gift will have.
      </p>
      <div class="flex options-flex">
        <div :class="{'options-container--disabled': !activeOption}" class="options-container">
          <div
            @click="selectOption(options[0].id)"
            :class="{'option--active': activeOption === 1}"
            class="option"
          >
            <span>Large</span>
            30kg CO₂ = {{(30 * this.currencyValue).toFixed(0)}} {{ currency }}/person
          </div>
          <div
            @click="selectOption(options[1].id)"
            :class="{'option--active': activeOption === 2}"
            class="option"
          >
            <span>Medium</span>
            20kg CO₂ = {{(20 * this.currencyValue).toFixed(0)}} {{ currency }}/person
          </div>
          <div
            @click="selectOption(options[2].id)"
            :class="{'option--active': activeOption === 3}"
            class="option"
          >
            <span>Small</span>
            10kg CO₂ = {{(10 * this.currencyValue).toFixed(0)}} {{ currency }}/person
          </div>
        </div>
        <div class="impact">
          <p class="impact__eyebrow">total impact</p>
          <p class="impact__number">
            {{ totalImpact.toFixed(1).replace(".", ",") }}<span>kg</span>
          </p>
          <p class="impact__description">
            of carbon dioxide permanently<br />removed from the air
          </p>
          <div
            :class="{'large': activeOption === 1, 'medium': activeOption === 2, 'small': activeOption === 3}"
            class="impact__trees"
          >
            <img class="tree" src="../assets/img/tree-light.svg" alt="" />
            <img class="tree" src="../assets/img/tree-dark.svg" alt="" />
            <img class="tree" src="../assets/img/tree-white.svg" alt="" />
            <img class="tree" src="../assets/img/tree-light.svg" alt="" />
            <img class="tree" src="../assets/img/tree-white.svg" alt="" />
            <img class="tree" src="../assets/img/tree-dark.svg" alt="" />
            <img class="tree" src="../assets/img/tree-white.svg" alt="" />
            <p class="impact__label">
              The same amount stored by {{ Math.round(totalImpact / 20)}} five-meter tall trees over 15 years
            </p>
          </div>
        </div>
      </div>
      <div>
        <div class="summary-total">
          <p class="summary-total__text">Total impact of your gift</p>
          <p class="summary-total__number">
            {{ totalImpact.toFixed(1).replace(".", ",") }}
            <span>kg</span>
          </p>
          <p class="summary-total__description">of carbon dioxide permanently removed from the air</p>
        </div>
      </div>
      <Form
        v-bind:currency="currency"
        v-bind:total="totalPrice"
        v-bind:employees="employees"
        v-bind:package="formule"
        :impact="Math.round(totalImpact / 20)"
      />
      <Success />
    </div>
    <div class="last-row">
      <div class="flex">
        <div class="total">
          <div class="total__number">
            Total:
            <span>{{ totalPrice.toFixed(1).replace(".", ",") }}</span>
            <div class="total__select">
              <select @change="updateCurrency" name="currency" id="currency">
                <option value="EUR">EUR</option>
                <option value="USD">USD</option>
                <option value="CHF">CHF</option>
              </select>
            </div>
          </div>
          <p class="total__description">
            Excluding applicable taxes, if any.
          </p>
        </div>
        <button
          :class="{'disabled': !allowOrder}"
          class="validate-button cta-button"
          @click="showSummary"
        >
          Order your gift
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Form from './Form.vue';
import Success from './Success.vue';

export default {
  name: 'Calculator',
  components: {
    Form,
    Success,
  },
  data: () => {
    return {
        activeOption: null,
        allowOrder: false,
        currency: 'EUR',
        currencyValue: 1,
        employees: null,
        formule: 'large',
        summary: false,
        totalImpact: 0,
        totalPrice: 0,
        options: [
          {
            id: 1,
            kg: 30,
            price: 30,
          },
          {
            id: 2,
            kg: 20,
            price: 20,
          },
          {
            id: 3,
            kg: 10,
            price: 10,
          },
        ]
      };
    },
  methods: {
    forbidLetter(object) {
      object.value = object.value.replace(/[^0-9.]/g, '');
      object.value = object.value.replace(/(\..*)\./g, '$1');
      this.employees = object.value.replace(/(\..*)\./g, '$1');
    },
    handleSum() {
      this.forbidLetter(document.querySelector('input[name="employees"]'));
      if (!this.activeOption && this.employees) {
        this.activeOption = 1;
      }
      this.totalImpact = this.employees * this.options[this.activeOption-1].kg;

      this.totalPrice = this.currency === 'EUR' ?
      this.employees * this.options[this.activeOption-1].price :
      this.employees * (this.options[this.activeOption-1].price * 1.1) ;

      if (this.employees > 0 && this.allowOrder !== true) {
        this.allowOrder = true;
      } else if (this.employees < 1 && this.allowOrder !== false) {
        this.allowOrder = false;
      }
    },
    updateCurrency(e) {
      this.currency = e.target.value;
      this.currencyValue = e.target.value === 'EUR' ? 1 : 1.1;
      this.handleSum();
    },
    selectOption(option) {
      if (this.employees > 0) {
        this.activeOption = option;
        this.handleSum();
        switch (option) {
          case 1:
            this.formule = 'large';
            break;
          case 2:
            this.formule = 'medium';
            break;
          case 3:
            this.formule = 'small';
            break;
          default:
            break;
        }
      }
    },
    showSummary() {
      if (this.employees > 0) {
        this.summary = true;
      }
    },
    backToCalculator() {
      this.summary = false;
    }
  }
}
</script>

<style scoped lang="scss">
$border-radius: 40px;
$color-grey: #888888;

.calculator {
  pointer-events: none;
  transform: rotateY(180deg);
  z-index: 0;
  padding: 34px 52px;

  @media screen and (max-width: 769px) {
    padding: 21px 23px 60px;
    transform: translateY(20px);
    opacity: 0;
  }
}

.first-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  @media screen and (max-width: 769px) {
    display: block;
  }

  &__text {
    font-family: Albra;
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
    max-width: 187px;
    text-align: center;

    @media screen and (max-width: 769px) {
      max-width: unset;
      margin-bottom: 20px;
    }

    span.blue-text {
      color: #5DBAD8;
    }
  }
}

.input-wrapper {
  align-items: center;
  background: #E8F4FA;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: flex;
  height: 98px;
  justify-content: space-between;
  padding: 21px 27px;
  width: auto;
  transition: width .3s ease-in-out;
  min-width: 385px;

  @media screen and (max-width: 769px) {
    display: block;
    height: auto;
    text-align: center;
    min-width: 100%;
  }

  &--summary {
    width: 385px;

    @media screen and (max-width: 769px) {
      width: 100%;
      padding: 21px 27px 50px;
    }

    .input input {
      background: #fefefe;
      pointer-events: none;
    }
  }
}

.input-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  margin-right: 35px;
  max-width: 200px;
  transition: max-width .4s ease-in-out;

  @media screen and (max-width: 769px) {
    margin-bottom: 14px;
    margin-right: 0;
    max-width: unset;
    position: relative;
  }

  &--summary {
    max-width: 385px;

    span {
      font-weight: 400;
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;
      display: block;

      @media screen and (max-width: 769px) {
        bottom: -114px;
        left: 0;
        position: absolute;
        right: 0;
      }
    }
  }
}

.input {
  @media screen and (max-width: 769px) {
    width: 100%;
  }

  input {
    align-items: center;
    background: #8DC8E8;
    border-radius: $border-radius;
    border: none;
    color: #1a1a1a;
    display: flex;
    font-family: Centra, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 66px;
    justify-content: center;
    text-align: center;
    width: 98px;
    transition: background .3s ease-in-out;

    &:focus::placeholder {
      color: transparent;
    }

    @media screen and (max-width: 769px) {
      width: 102px;
      margin: 0 auto;
    }

    &::placeholder {
      color: #1a1a1a;
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
  }
}

.second-row {
  background: #E8F4FA;
  border-radius: $border-radius;
  padding: 22px 35px 19px;
  position: relative;
  transition: all .3s ease-in-out;

  &.hidden {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.summary {
    padding: 22px 35px 0;

    @media screen and (max-width: 769px) {
      padding: 22px 35px 40px;
    }
  }

  &__description {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    margin: 0 auto 24px;
    max-width: 250px;
    text-align: center;
    opacity: 1;
    transition: opacity .3s ease-in-out .4s, transform .3s ease-in-out .4s;
  }
}

.flex {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 769px) {
    display: block;
  }
}

.options-container {
  height: 225px;

  &--disabled .option {
    cursor: not-allowed;
  }
}

.option {
  background: #ffffff;
  border-radius: $border-radius;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 14px 27px;
  text-align: center;
  width: 214px;
  position: absolute;
  transition: background .3s ease-in-out, opacity .3s ease-in-out .5s, top .8s ease-in-out .5s;

  @media screen and (max-width: 769px) {
    position: static;
    margin: 0 auto 10px;
  }

  &--active {
    background: #8DC8E8;
    transition: background .3s ease-in-out, opacity .3s ease-in-out, top .8s ease-in-out;
  }

  &:first-child { top: 82px; }
  &:nth-child(2) { top: 157px; }
  &:last-child { top: 232px; }

  span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.4px;
  }
}

.impact {
  max-width: 263px;
  opacity: 1;
  text-align: center;
  transform: translateY(0);
  transition: opacity .3s ease-in-out .5s, transform .3s ease-in-out .5s;
  width: 247px;

  @media screen and (max-width: 769px) {
    max-width: 100%;
    width: 100%;
    margin-top: 18px;
  }

  &__eyebrow {
    color: #393940;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__number {
    font-family: Albra;
    font-size: 60px;
    font-weight: 700;

    span {
      font-family: Centra;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__description {
    position: relative;
    top: -7px;
    color: #393940;
    font-size: 9px;
    line-height: 9.9px;

    span {
      font-weight: 700;
    }
  }

  &__label {
    background: #fefefe;
    border-radius: 28px;
    bottom: 12px;
    box-sizing: border-box;
    color: #393940;
    font-size: 9px;
    left: 22px;
    line-height: 9.9px;
    max-width: 186px;
    padding: 8px 22px;
    position: absolute;
    z-index: 9;

  }
}

.impact__trees {
  height: 124px;

  @media screen and (max-width: 769px) {
    height: 84px;
    margin: 0 auto;
    position: relative;
    top: 16px;
    transform: scale(0.8);
    width: 240px;
  }

  .tree {
    position: absolute;
    bottom: -19px;
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity .8s ease-in-out, transform .7s ease-in-out;

    @media screen and (max-width: 769px) {
      bottom: -14px;
    }

    &:first-child {
      left: 0;
      width: 52px;
      z-index: 10;
    }
    &:nth-child(2) {
      left: 35px;
      width: 74px;
      z-index: 9;
    }
    &:nth-child(3) {
      left: 92px;
      width: 52px;
      z-index: 8;
    }
    &:nth-child(4) {
      left: 130px;
      width: 34px;
      z-index: 9;
    }
    &:nth-child(5) {
      left: 141px;
      width: 74px;
    }
    &:nth-child(6) {
      left: 165px;
      width: 57px;
      z-index: 7;
    }
    &:nth-child(7) {
      left: 208px;
      width: 35px;
      z-index: 6;
    }
  }

  &.medium .tree {
    &:nth-child(1) {
      transform: translateX(0);
    }
    &:nth-child(2),
    &:nth-child(5) {
      opacity: 0;
    }
    &:nth-child(3) {
      transform: translateX(-50px);
    }
    &:nth-child(4) {
      transform: translateX(-40px);
    }
    &:nth-child(6) {
      transform: translate(-48px, 4px) scale(0.92);
    }
    &:nth-child(7) {
      transform: translateX(-48px);
    }
  }
  &.small .tree {
    &:nth-child(2),
    &:nth-child(6),
    &:nth-child(7) {
      opacity: 0;
    }

    &:nth-child(1) {
      transform: translate(5px, 9px) scale(0.8);
    }
    &:nth-child(3) {
      transform: translate(-47px, 13px) scale(0.7);
    }
    &:nth-child(5) {
      transform: translate(-53px, 15.5px) scale(0.75);
    }
  }
}

.last-row {
  padding: 23px 65px 0;

    @media screen and (max-width: 769px) {
      padding: 23px 25px 0;
    }

  .flex {
    align-items: center;

    @media screen and (max-width: 769px) {
      display: block;
      text-align: center;
    }
  }
}

.total {
  text-align: center;
  max-width: 209px;

  @media screen and (max-width: 769px) {
    display: block;
    margin: 0 auto 28px;
  }

  &__number {
    font-size: 22px;

    span {
      font-weight: 700;
    }
  }

  &__description {
    color: $color-grey;
    font-size: 10px;
    line-height: 13px;
    margin-top: 6px;
    position: relative;
    top: -7px;
  }

  &__select {
    background: none;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 1px;
      background: #000000;
      bottom: 3px;
      width: 28px;
      left: 3px;
    }

    &:before {
      @media screen and (max-width: 769px) {
        content: "";
        position: absolute;
        z-index: 2;
        right: 3px;
        bottom: 6px;
        height: 0;
        width: 0;
        border-top: 8px solid #888888;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        pointer-events: none;
      }
    }

    select {
      background: none;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      font-family: Centra;
      border: none;

      // &::-ms-expand {
        // background: none;
        // display: block;
      // }

    }
  }
}

.cta-button {
  padding: 9px 43px;
  transition: background .3s ease-in-out, border-radius .2s ease-in-out;

  &.disabled {
    background: #F1F1F1;
    cursor: not-allowed;
    border: 2px solid #F1F1F1;

    &:hover {
      border-radius: 32px;
    }
  }
}

.validate-button {
  opacity: 1;
}

.summary-total {
  align-items: center;
  background: #ffffff;
  border-radius: $border-radius;
  box-sizing: border-box;
  display: flex;
  height: 68px;
  justify-content: space-between;
  min-width: 300px;
  opacity: 0;
  padding: 17px 30px 15px;
  pointer-events: none;
  position: absolute;
  right: 28px;
  top: 22px;
  transform: translateY(20px);
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;

  @media screen and (max-width: 769px) {
    left: 0;
    margin: auto;
    min-width: unset;
    position: absolute;
    right: 0;
    width: calc(100% - 70px);
    display: block;
    text-align: center;
    height: unset;
    padding: 16px 11px;
    text-align: center;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    max-width: 90px;

    @media screen and (max-width: 769px) {
      max-width: 100%;
    }
  }

  &__number {
    font-family: Albra;
    font-size: 40px;
    position: relative;
    top: 2px;

    span {
      font-size: 14px;
      font-family: Centra;
    }
  }

  &__description {
    color: #888888;
    font-size: 9px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: calc(100% + 9px);
  }
}

.summary {
  .second-row__description,
  .option:not(.option--active),
  .impact {
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
    transform: translateY(10px)
  }

  .validate-button {
    opacity: 0;
    pointer-events: none;
  }

  .impact {
    transform: translateY(10px)
  }

  .option--active {
    top: 22px;
    left: 30px;

    @media screen and (max-width: 769px) {
      position: absolute;
      left: 0;
      right: 0;
      top: 22px;
      width: calc(100% - 70px);
    }
  }

  .summary-total {
    opacity: 1;
    pointer-events: all;
    transition: opacity .5s ease-in-out .1s, transform .5s ease-in-out .1s;
    transform: translateY(0);

    @media screen and (max-width: 769px) {
      top: 100px;
    }
  }
}

.formSent {
  .validate-button,
  .summary-total,
  .options-flex,
  .option {
    opacity: 0;
    pointer-events: none;
  }
}
</style>
